import React, { useState, useContext, useEffect } from 'react';
import { Table, Input, Popconfirm, Form, Typography, Button, Select, message } from 'antd';
import { DeleteOutlined, PlusOutlined, SaveOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { AppContext } from '../../AppContext';
import firebase from '../../utils/firebaseConfig';
import './JobListings.css';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  isRecruiter,
  ...restProps
}) => {
  const getPlaceholder = (dataIndex) => {
    switch (dataIndex) {
      case 'role':
        return 'Enter job title or role (include URL to make it clickable)';
      case 'company':
        return 'Enter company name';
      case 'recruiter':
        return 'Enter recruiter name';
      default:
        return `Enter ${title}`;
    }
  };

  let inputNode;
  if (inputType === 'select') {
    inputNode = (
      <Select placeholder="Select job status">
        <Option value="applied">Applied</Option>
        <Option value="interviewing">Interviewing</Option>
        <Option value="offered">Offered</Option>
        <Option value="rejected">Rejected</Option>
        <Option value="accepted">Accepted</Option>
      </Select>
    );
  } else if (dataIndex === 'role') {
    inputNode = (
      <Input.Group compact>
        <Form.Item
          name="role"
          noStyle
          rules={[{ required: true, message: 'Role is required' }]}
        >
          <Input placeholder="Enter job title or role" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item
          name="linktojob"
          noStyle
        >
          <Input placeholder="Optional job URL" style={{ width: '40%' }} />
        </Form.Item>
      </Input.Group>
    );
  } else {
    inputNode = (
      <Input placeholder={getPlaceholder(dataIndex)} />
    );
  }

  // If it's the recruiter field and user is not a recruiter, show text instead
  if (dataIndex === 'recruiter' && !isRecruiter) {
    return (
      <td {...restProps}>
        {children}
      </td>
    );
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please enter ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const JobListings = ({ admin, searchText, isRecruiter }) => {
  const [form] = Form.useForm();
  const { currentJobSeeker } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentJobSeeker && currentJobSeeker.jobsapplied) {
      const jobsArray = Object.entries(currentJobSeeker.jobsapplied).map(([key, job]) => ({
        key,
        ...job
      }));
      setData(jobsArray);
    }
  }, [currentJobSeeker]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      role: '',
      company: '',
      status: 'applied',
      recruiter: '',
      linktojob: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleDelete = async (key) => {
    try {
      setLoading(true);
      const updatedJobs = { ...(currentJobSeeker.jobsapplied || {}) };
      delete updatedJobs[key];

      await firebase
        .database()
        .ref(`users/${currentJobSeeker.uid}/info`)
        .update({
          jobsapplied: updatedJobs,
          jobsappliedto: Object.keys(updatedJobs).length
        });

      setData(data.filter(item => item.key !== key));
      message.success('Job deleted successfully');
    } catch (error) {
      console.error('Error deleting job:', error);
      message.error('Failed to delete job: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const save = async (key) => {
    try {
      setLoading(true);
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        const updatedItem = {
          ...item,
          ...row,
        };
        newData.splice(index, 1, updatedItem);

        // If this is a new record (key starts with 'new-')
        if (key.startsWith('new-')) {
          const jobKey = firebase.database().ref().child('jobs').push().key;
          const updatedJobs = { ...(currentJobSeeker.jobsapplied || {}) };
          updatedJobs[jobKey] = {
            ...row,
            dateappliedto: new Date().toISOString(),
            recruiter: isRecruiter ? row.recruiter : 'Pending'
          };

          await firebase
            .database()
            .ref(`users/${currentJobSeeker.uid}/info`)
            .update({
              jobsapplied: updatedJobs,
              jobsappliedto: Object.keys(updatedJobs).length
            });

          // Update the local data with the new key
          updatedItem.key = jobKey;
          newData.splice(index, 1, updatedItem);
        } else {
          // Update existing job
          const updatedJobs = { ...(currentJobSeeker.jobsapplied || {}) };
          updatedJobs[key] = {
            ...row,
            dateappliedto: item.dateappliedto,
            recruiter: isRecruiter ? row.recruiter : item.recruiter
          };

          await firebase
            .database()
            .ref(`users/${currentJobSeeker.uid}/info`)
            .update({
              jobsapplied: updatedJobs,
              jobsappliedto: Object.keys(updatedJobs).length
            });
        }

        setData(newData);
        setEditingKey('');
        message.success('Job saved successfully');
      }
    } catch (error) {
      console.error('Error saving job:', error);
      message.error('Failed to save job: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    const newKey = `new-${Date.now()}`;
    const newRecord = {
      key: newKey,
      role: '',
      company: '',
      dateappliedto: new Date().toISOString(),
      status: 'applied',
      recruiter: isRecruiter ? '' : 'Pending',
      linktojob: ''
    };
    setData([newRecord, ...data]);
    edit(newRecord);
  };

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      width: '25%',
      editable: true,
      render: (text, record) => {
        const editable = isEditing(record);
        if (editable) {
          return text;
        }
        return record.linktojob ? (
          <a href={record.linktojob} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ) : (
          text
        );
      },
      sorter: (a, b) => a.role.localeCompare(b.role)
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: '20%',
      editable: true,
      sorter: (a, b) => a.company.localeCompare(b.company)
    },
    {
      title: 'Date Applied',
      dataIndex: 'dateappliedto',
      width: '20%',
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.dateappliedto) - new Date(b.dateappliedto)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      editable: true,
      render: (text) => (
        <span className={`status-tag status-${text.toLowerCase()}`}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status)
    },
    {
      title: 'Recruiter',
      dataIndex: 'recruiter',
      width: '15%',
      editable: isRecruiter
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              <SaveOutlined /> Save
            </Typography.Link>
            <Typography.Link onClick={cancel} style={{ marginRight: 8 }}>
              <CloseOutlined /> Cancel
            </Typography.Link>
          </span>
        ) : (
          <span>
            <Typography.Link 
              disabled={editingKey !== ''} 
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
            >
              <EditOutlined />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => handleDelete(record.key)}
              style={{ color: '#ff4d4f' }}
            >
              <DeleteOutlined />
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'status' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        isRecruiter
      }),
    };
  });

  const filteredData = data.filter((job) => {
    if (!searchText) return true;
    const searchLower = searchText.toLowerCase();
    return (
      job.role.toLowerCase().includes(searchLower) ||
      job.company.toLowerCase().includes(searchLower) ||
      job.recruiter.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="job-listings">
      <Button
        type="primary"
        onClick={handleAdd}
        style={{ marginBottom: 16 }}
        icon={<PlusOutlined />}
        disabled={editingKey !== ''}
      >
        Add Job
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          loading={loading}
          pagination={{
            pageSize: 10,
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
};

export default JobListings;
