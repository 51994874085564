import React, { createContext, useState } from 'react';

// Create a new context
const AppContext = createContext();

// Create a custom provider component for the context
const AppProvider = ({ children }) => {
  const [currentJobSeeker, setCurrentJobSeeker] = useState({});
  const [currentRecruiter, setCurrentRecruiter] = useState({});

  const updateJobSeeker = (updatedJobSeeker) => {
    setCurrentJobSeeker(updatedJobSeeker);
  };

  return (
    <AppContext.Provider
      value={{
        currentJobSeeker,
        setCurrentJobSeeker,
        updateJobSeeker,
        currentRecruiter,
        setCurrentRecruiter
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
