import { database, dbref, dbOnValue } from './dataobject';
//import axios from 'axios';
import 'firebase/compat/auth';
import 'firebase/compat/database';
//import {twilioConfig,helpMessage} from './constants'

const jobseekerDataPath = 'jobseekers';
//const recruiterDataPath = 'recruiter';
const usersDataPath = 'users';


export const getJobSeekers = () => {
  return new Promise((resolve, reject) => {
    
    const currentJobSeekers = dbref(database, jobseekerDataPath);
    const jobSeekers = [];

    dbOnValue(
      currentJobSeekers,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the object of users into an array of user info objects
          const jobSeekersInfo = Object.values(data).map((user) => user);
          jobSeekers.push(...jobSeekersInfo);
        }
        // Resolve the promise with the users' info
        resolve(jobSeekers);
      },
      (error) => {
        // Reject the promise if there's an error
        reject(error);
      }
    );

    return jobSeekers;
  });
};


export const fetchUserInfo =(user)=>{
const uid = user.uid

const currentUserPath = dbref(database, usersDataPath + "/" + uid);

return new Promise( (resolve, reject) => {
  var  applyStormUser ;
  dbOnValue(currentUserPath,
  (snapshot)=>{
    const data = snapshot.val();
  if (data) {
    console.log("::::::::::" + JSON.stringify(data))
    applyStormUser = data["info"]
  }
  resolve(applyStormUser);
  },
  (error)=> {
    reject(error)
  }

);

return applyStormUser;
});
}