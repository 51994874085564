import React from 'react';
import './LandingPageHeader.css';
import headerlogo from '../../images/LOGO-Header@2x.svg';

const LandingPageHeader = () => (
  <div className="LandingPageHeader">
    <div className="menu-header">
      <div>
        <img className="logo-img" alt="logo" height={30} width={200} src={headerlogo} />
      </div>
      <div>
        <ul>
          <li><a href="#howitworks">How it Works</a></li>
          <li><a href="#pricing">Pricing</a></li>
          
          <li><a href="/login">Login/Sign Up</a></li>
          <li>{/* <a href="faq">FAQ</a> */}</li>
        </ul>
      </div>
    </div>
  </div>
);



export default LandingPageHeader;
