// import React, { useState, useContext } from 'react';
import React, { useState } from 'react';
import './Login.css';
import firebase from '../../utils/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { fetchUserInfo } from '../../utils/api';
//import { AppContext } from '../../AppContext';

const Login = () => {
  //const { setAppUser } = useContext(AppContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  
  const processLogin= async (user)=> {
    var currentUser = {}
    try {
      currentUser = fetchUserInfo(user)
    } catch(error) {
      console.log(error)
    } finally {
      console.log(currentUser)
      console.log( fetchUserInfo(user).uid)
    }

 

    // check to see if the users id exists in firebase
    // if it does not, then capture as dead users, then redirect to the pricing page
    // if it does exist, 
    // verify that the sub id is active in stripe (later)
    // then see what type of user they are
      // if they are a jobseeker, then redirect to their view
      // else then redirect to recruiter view
  }
  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;

      //setAppUser(user);
      if (user) {

        processLogin(user)
        navigate('/recruiterview');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleGoogleLogin}>Login with Google</button>
      <p>
        If you do not have an account, please <a href="/signup">Sign Up Here </a>
      </p>
    </div>
  );
};

export default Login;
