import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

export const firebaseConfig = {
  apiKey: 'AIzaSyAFhl9SSmN0YjBIigToubAfhF9xdYmXmgY',
  authDomain: 'applystorm-de9c1.firebaseapp.com',
  databaseURL: 'https://applystorm-de9c1-default-rtdb.firebaseio.com/',
  projectId: 'applystorm-de9c1',
  storageBucket: 'applystorm-de9c1.appspot.com',
  messagingSenderId: '1026762939606',
  appId: '1:1026762939606:web:a2a55ffa96f4f4e38afd77',
  measurementId: 'G-HF5S783BDL'
};

// Initialize Firebase
let firebaseApp;
try {
  firebaseApp = firebase.app();
} catch (error) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

// Database reference
const database = firebaseApp.database();

// Job management functions
export const jobsRef = database.ref('jobs');

export const addJob = async (jobData) => {
  try {
    const newJobRef = jobsRef.push();
    await newJobRef.set({
      ...jobData,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      updatedAt: firebase.database.ServerValue.TIMESTAMP
    });
    return newJobRef.key;
  } catch (error) {
    console.error('Error adding job:', error);
    throw error;
  }
};

export const updateJob = async (jobId, jobData) => {
  try {
    await jobsRef.child(jobId).update({
      ...jobData,
      updatedAt: firebase.database.ServerValue.TIMESTAMP
    });
  } catch (error) {
    console.error('Error updating job:', error);
    throw error;
  }
};

export const deleteJob = async (jobId) => {
  try {
    await jobsRef.child(jobId).remove();
  } catch (error) {
    console.error('Error deleting job:', error);
    throw error;
  }
};

export const getJobsByRecruiter = async (recruiterId) => {
  try {
    const snapshot = await jobsRef.orderByChild('recruiterId').equalTo(recruiterId).once('value');
    return snapshot.val() || {};
  } catch (error) {
    console.error('Error getting jobs by recruiter:', error);
    throw error;
  }
};

export const getJobsByJobSeeker = async (jobSeekerId) => {
  try {
    const snapshot = await jobsRef.orderByChild('jobSeekerId').equalTo(jobSeekerId).once('value');
    return snapshot.val() || {};
  } catch (error) {
    console.error('Error getting jobs by job seeker:', error);
    throw error;
  }
};

// User management functions
export const usersRef = database.ref('users');

export const updateUserProfile = async (userId, profileData) => {
  try {
    await usersRef.child(userId).child('info').update({
      ...profileData,
      updatedAt: firebase.database.ServerValue.TIMESTAMP
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const snapshot = await usersRef.child(userId).child('info').once('value');
    return snapshot.val();
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw error;
  }
};

export const checkoutJobSeeker = async (jobSeekerId, recruiterId) => {
  try {
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    await usersRef.child(jobSeekerId).update({
      checkedOutBy: recruiterId,
      lastActivity: timestamp
    });
    return true;
  } catch (error) {
    console.error('Error checking out job seeker:', error);
    throw error;
  }
};

export const checkInJobSeeker = async (jobSeekerId) => {
  try {
    await usersRef.child(jobSeekerId).update({
      checkedOutBy: null,
      lastActivity: null
    });
    return true;
  } catch (error) {
    console.error('Error checking in job seeker:', error);
    throw error;
  }
};

export const updateLastActivity = async (jobSeekerId) => {
  try {
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    await usersRef.child(jobSeekerId).update({
      lastActivity: timestamp
    });
    return true;
  } catch (error) {
    console.error('Error updating last activity:', error);
    throw error;
  }
};

// File storage functions
const storage = firebase.storage();

export const uploadFile = async (file, path) => {
  try {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);
    await fileRef.put(file);
    return await fileRef.getDownloadURL();
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export default firebase;
