import React, { useEffect, useState, useContext } from 'react';
import './JobSeekerPicker.css';
import { getJobSeekers } from '../../utils/api';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';

const JobSeekerPicker = () => {
  const [allJobSeekers, setAllJobSeekers] = useState([]);
  const { setCurrentJobSeeker } = useContext(AppContext);

  const navigate = useNavigate();

  const selectJobSeeker = async (e, jobSeeker) => {
    // let jobSeekerID = e.currentTarget.getAttribute('data-value');
    await setCurrentJobSeeker(jobSeeker);
    navigate('/applyview/' + jobSeeker.uid);
  };
  useEffect(() => {
    //setAllJobSeekers(getJobSeekers)
    // console.log(allJobSeekers)

    const fetchjobSeekers = async () => {
      setAllJobSeekers([]);
      try {
        const usersData = await getJobSeekers();
       // console.log('THIS IS USERSDATA[1] ::' + usersData[1].name);
        setAllJobSeekers(usersData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchjobSeekers();
  }, []);

  return (
    <div className="jobseeker-list">
      {Object.entries(allJobSeekers).map((jobSeekers) => {
        const jobSeeker = jobSeekers[1];
        return (
          <Card
            title={jobSeeker.name}
            bordered={false}
            style={{
              width: 300
            }}
            key={jobSeeker.uid}
            data-value={jobSeeker.uid}
            data-value2={jobSeeker.uid}
            jobseeker-data={jobSeeker}
            onClick={(event) => selectJobSeeker(event, jobSeeker)}
          >
            <div className="jobseeker-card-item">
              <div>
                <a href={jobSeeker.email}>{jobSeeker.email}</a>
              </div>
              <div> Jobs Applied To : {jobSeeker.jobsappliedto} / 000</div>
              <ul>
                {jobSeeker.rolestoapplyfor.map((item) => {
                  const role = item;
                  return (
                    <>
                      <li>{role}</li>
                    </>
                  );
                })}
              </ul>
            </div>
          </Card>
        );
      })}
    </div>
  );
};


export default JobSeekerPicker;
