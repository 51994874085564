import React from 'react';

import './MasterPage.css';

//const { Header, Footer, Sider, Content } = Layout;

const MasterPage = ({ child }) => (
  <div className="MasterPage">
    <div className="header-container">
      <div></div>
      <div></div>
    </div>

    <div className="main-content">{child}</div>
  </div>
);





export default MasterPage;
